import React from "react";
import { Link } from "react-router-dom";
import { TbMenu2 as IconMenu, TbX as IconMenuClose } from 'react-icons/tb';
import TreeLogo from '../assets/images/TreeLogo30_medium.webp';
//import MenuIcon from '../assets/images/MenuIconLight.png';
import "./Header.css";

export const Header = ( { menuClicked, menuActive } ) => {

    return (
        <header className="header-bar">
            <div className="header-content">
                <Link to="/">
                    <img className="header-logo" src={TreeLogo} alt="Tree Logo" />
                    <div className="header-title">HALLO BAUM</div>
                </Link>
                { menuActive ? 
                    <IconMenuClose className="header-menu-icon" onClick={menuClicked} alt="Menu" /> :
                    <IconMenu className="header-menu-icon" onClick={menuClicked} alt="Menu" />
                }
                <div className="header-menu-container">
                    <div className="header-menu-item header-menu-desktop-only">
                        <Link to="/about">Idee</Link>
                    </div>

                    <div className="header-menu-item header-menu-desktop-only">
                        <Link to="/science">Funktionsweise</Link>
                    </div>

{
                    <div className="header-menu-item header-menu-desktop-only">
                        <Link to="/contribute">Mitmachen</Link>
                    </div>
}
{/*
                    <div className="header-menu-item header-menu-desktop-only">
                        <Link to="/contactform">Kontakt</Link>
                    </div>
*/}
{/*
                    <div className="header-menu-item">
                        <IconPlay className="header-menu-item-icon"/>
                        <a href="https://hallobaum.de/app" target="_blank" rel="noreferrer">Zur App</a>
                    </div>
*/}

{/*
                    <div className="header-menu-item header-menu-desktop-only">
                        <Link to="/legalnotice">Impressum</Link>
                    </div>
                    <div className="header-menu-item header-menu-desktop-only">
                        <Link to="/dataprivacy">Datenschutz</Link>
                    </div>
*/}
                </div>
                { 
                //<img className="header-menu-icon" src={MenuIcon} onClick={menuClicked} alt="Menu" />
                }
            </div>
        </header>
    )
}