import React from "react";
import "./DownloadItem.css";
import { TbDownload } from "react-icons/tb";

export const DownloadItem = ( { image, imageAlt, title, link } ) => {

    return (
       <div className="download-item">
          <div className="download-title">{title}</div>
          <a href={link} download>
          <img className="download-image" src={image} alt={imageAlt} />
          <TbDownload className="download-icon" />
          </a>
       </div>
    )
}
