import React from "react";
import { Link } from "react-router-dom";
//import { AiFillCalculator as IconCalc, AiFillInfoCircle as IconInfo, AiFillDatabase as IconDatabase} from 'react-icons/ai';
//import { FiCalculator as IconCalc, FiInfo as IconInfo, FiDatabase as IconDatabase} from 'react-icons/fi';
//import { GrCalculator as IconCalc, GrCircleInformation as IconInfo, GrDatabase as IconDatabase} from 'react-icons/gr';
import { TbMath as IconCalc, TbInfoCircle as IconInfo,
         //TbDatabase as IconDatabase,
         //TbX as IconClose,
         //TbPlayerPlayFilled as IconPlay,
         //TbBike as IconActive,
         TbUsers as IconContribute,
         //TbQuestionMark as IconQuestion, TbExclamationMark as IconExclamation, 
        } from 'react-icons/tb';
import "./Navbar.css";

export const Navbar= ( { active, onMenuClick }) => {
  return (
  <nav className={active ? "navbar active" : "navbar"}>
    <ul onClick={onMenuClick}>
      {/*
        <li>
          <Link to="/"><IconClose className="menu-list-icon"/></Link>
        </li>
  */}
        <li>
          <Link to="/about"> <IconInfo className="menu-list-icon"/><span className="menu-list-text">Die Idee</span></Link>
        </li>
        <li>
          <Link to="/science"> <IconCalc className="menu-list-icon"/><span className="menu-list-text">Funktionsweise</span></Link>
        </li>
        <li>
          <Link to="/contribute"> <IconContribute className="menu-list-icon"/><span className="menu-list-text">Mitmachen</span></Link>
        </li>
{/*        

        <li>
          <a href="https://hallobaum.de/app" target="_new"> <IconPlay className="menu-list-icon"/><span className="menu-list-text">Web-App starten</span></a>
        </li>
*/}        
{/*        
        <li>
          <Link to="/howto"><IconQuestion className="menu-list-icon menu-list-icon-shiftl" /><IconExclamation className="menu-list-icon menu-list-icon-shiftr"/>
          <span className="menu-list-text">Wie funktioniert das?</span></Link>
        </li>
        <li>
          <Link to="/action"><IconActive className="menu-list-icon"/> <span className="menu-list-text">Aktiv werden</span></Link>
        </li>

        <hr/>

        <li>
          <Link to="/datasources"><IconDatabase className="menu-list-icon"/> <span className="menu-list-text">Datenquellen</span></Link>
        </li>

*/}

        <hr/>

        <li>
          <Link to="/legalnotice"><span className="menu-list-text">Impressum</span></Link>
        </li>
        <li>
          <Link to="/dataprivacy"><span className="menu-list-text">Datenschutz</span></Link>
        </li>
    </ul>
  </nav>
  );
}
