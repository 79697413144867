import './App.css';
import React, { useState } from "react";
import { Routes, Route } from 'react-router-dom';
//import { useNavigate } from 'react-router-dom';
import { Header, Footer, Navbar, ScrollToTop } from "./components"
import { MainPage, About, Science, Contribute, LegalNotice, DataPrivacy, NotFound } from "./pages"


function App() {

  const [navbarActive, setnavbarActive] = useState(false);

  function toggleNavbar () {
    setnavbarActive(!navbarActive);
  }

  return (
    <div className="App">

      {navbarActive ? <div className="page-shade" onClick={toggleNavbar}></div> : null}
      <Navbar active={navbarActive} onMenuClick={toggleNavbar} />

      <Header menuActive={navbarActive} menuClicked={toggleNavbar} />

      <div className='page-content'>
      <ScrollToTop>
        <Routes>
          <Route path="/prerender/" exact element={<MainPage/>}  />
          <Route path="/" exact element={<MainPage/>}  />
          <Route path="/prerender/about" element={<About/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/contribute" element={<Contribute/>} />
          <Route path="/science" element={<Science/>} />
{/*          <Route path="/datasources" element={<DataSources/>} />
          <Route path="/contactform" element={<ContactForm/>} />
  */}
          <Route path="/legalnotice" element={<LegalNotice/>} />
          <Route path="/dataprivacy" element={<DataPrivacy/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      </div>
      
      <Footer />

    </div>
  );
}

export default App;
