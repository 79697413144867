import { React, useState, useEffect } from 'react';
import PosterThumb from '../assets/thumbs/HalloBaum_poster_thumb.png';
import ImageSocialMedia from '../assets/thumbs/HalloBaum_social_media.png';
import { CgSpinner as Spinner } from 'react-icons/cg';
import { DownloadItem } from '../components';


export const callTreeAPI = async(apiCommand, postBody, debugAPI, timeout, addClientInfo=true) => {

  var url = "https://hallobaum.de/api/" + apiCommand;
  //var url = "https://localhost:5000/" + apiCommand;

  timeout = timeout || 5000;
  debugAPI = debugAPI || false;

  // Debug tree server
  //if (window.location.origin.indexOf('3000') > 0) {
      //url = window.location.origin.replace('3000', '5000') + "/" + apiCommand;
  //}
  if (debugAPI) {
      url = window.location.origin.replace('3000', '5000').replace('4173', '5000') + "/" + apiCommand;
      //url = "https://192.168.0.91:5000/" + apiCommand;
  }

  console.log(postBody);
  const controller = new AbortController()
  // timeout in ms:
  //const timeoutId = setTimeout(() => controller.abort(), 5000);
  setTimeout(() => controller.abort(), timeout);
  const requestOptions = {
      signal: controller.signal,  // for timeout
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postBody)
  };

  return fetch(url, requestOptions)
      .then(response => { return response.json().then(data => {
          //console.log("************* RESPONSE RECEIVED");
          if (!response.ok) {
              const error = response.statusText;
              //console.log("************* RESPONSE NOT OK");
              //console.log(error);
              return Promise.reject(error);
          }
  
          return data;
      });
      }).catch(error => {
          console.log('API error: ', error.message);
          return null; 
      });

}

export const Contribute = () => {

  const [linkList1, setLinkList1] = useState(null);
  const [linkList2, setLinkList2] = useState(null);

  useEffect(() => {
    callTreeAPI('getcontent', {"par": "actionlinks"}, false).then(
      data => {
          if (data != null) {
            if ('giessplattformen' in data) setLinkList1(data['giessplattformen']);
            if ('gruenpatenschaften' in data) setLinkList2(data['gruenpatenschaften']);
          }
    });
  }, []);
  

  return (
    <div className="page-content text-hyphenated">
        <div className='content-block content-block-inner'>

        <h1>Mitmachen</h1>

        Es gibt viele Möglichkeiten, wie du die Bäume in deiner Stadt und die "Hallo Baum"-App unterstützen kannst.
        
        <h2>1. Weitersagen</h2>

        Erzähle allen von Hallo Baum, denn so können mehr Menschen etwas über die Bäume um sie herum lernen und
        sich mit ihnen anfreunden.<br />
        <br />
        Das <b>Hallo Baum Poster</b> kannst du ausdrucken und aufhängen. Aber natürlich nur dort, wo du es auch
        aufhängen darfst, z.B. am eigenen Fenster oder Fahrrad oder an einer Stelle, für die du eine
        Erlaubnis bekommen hast.<br />
        <br />
        Das <b>Hallo Baum Social-Media-Bild</b> kannst du herunterladen und in deinen Social-Media-Kanälen verbreiten.

        <div className="download-list">
        <DownloadItem image={PosterThumb}
                      imageAlt="Hallo Baum Poster"
                      title="Poster (PDF)"
                      link="/downloads/HalloBaum_poster.pdf"></DownloadItem>

        <DownloadItem image={ImageSocialMedia}
                      imageAlt="Hallo Baum Social Media Bild" 
                      title="Social Media (PNG)"
                      link="/downloads/HalloBaum_social_media.png"></DownloadItem>
        </div>


        <br/>
        <h2>2. Gießen im Sommer</h2>
        In den Sommern ist es oft sehr trocken. Darunter leidet auch der Baum vor deiner Tür.
        Besonders jüngere Bäume, die nicht mehr von der Stadt gepflegt werden, aber noch keine tiefen Wurzeln haben,
        haben es dann oft schwer. Du kannst ihnen ganz einfach mit einem gelegentlichen Eimer Wasser helfen.<br />
        <br />
        Manche Städte bieten dazu sogar Gießprogramme und Plattformen an, bei denen man sich und seinen Baum anmelden kann.
        Wenn deine Stadt nicht in dieser Liste steht, schau doch mal nach, ob sie nicht trotzdem ein Gießprogramm anbietet.
        <br />
        
        { linkList1 ? 
                <table className="link-table">
                <tbody>
                { linkList1.map((el) => 
                    <tr key={el.city}>
                      <td>{el.city}</td>
                      <td><a href={el.link} rel="nofollow" target="_blank">{el.link_text}</a></td>
                    </tr>) 
                }
                </tbody>
                </table>
          : <center><Spinner className='spinner-icon-small'/></center>
        }

        <br/>

        <h2>3. Grünpatenschaften</h2>
        In vielen Städten kannst du Grün- oder Baumpatenschaften übernehmen und einen Bereich im
        öffentlichen Raum, z.B. an deinem Baum, selbst bepflanzen.
        Das ist gut für den Boden und die Insektenvielfalt und freut
        somit auch die Vögel, die deinen Baum besuchen.
        Links zu den ensprechenden Stellen einiger bei Hallo Baum vertretener Städte findest du hier.
        Aber auch viele andere Städte bieten Grünpatenschaften an.
        <br />

        { linkList2 ? 
                <table className="link-table">
                <tbody>
                { linkList2.map((el) => 
                    <tr key={el.city}>
                      <td>{el.city}</td>
                      <td><a href={el.link} rel="nofollow" target="_blank">{el.link_text}</a></td>
                    </tr>) 
                }
                </tbody>
                </table>
          : <center><Spinner className='spinner-icon-small'/></center>
        }

        <br/>

        <h2>4. Aufräumen</h2>
        Der Boden um deinen Baum herum, die sogenannte "Baumscheibe", sollte immer frei von Müll und abgestellten
        Gegenständen gehalten werden. Das ist wichtig, damit immer genug sauberes Wasser in den Boden eindringen kann
        und die Wurzeln nicht beschädigt werden.
        Auch kann man darauf achten, dass Autos nicht zu dicht am Baum parken, damit der Boden nicht verdichtet wird
        und der Stamm nicht verletzt werden kann.

        </div>
    </div>
  );
}
