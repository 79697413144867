import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export const Footer = ( ) => {

    return (
       <div className="footer-bar">
              <span className="footer-menu-text">&#x00A9; 2024 Thomas Amthor</span>
              <span>
                     <Link to='/legalnotice'><button className="footer-menu-button">Impressum</button></Link>
              </span>
              <span>
                     <Link to='/dataprivacy'><button className="footer-menu-button">Datenschutz</button></Link>
              </span>
       </div>
    )
}
